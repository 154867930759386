import { AxiosResponse } from 'axios'
import { NewsCreateResponse } from 'src/types/news'

import axios from '../../axios'

export type NewsCreateRequestBody = {
  title: string
  content: string
  files: File[]
  isNotifyAll: boolean
  agencyCodeList: string[]
}

export const createNews = async (
  requestBody: NewsCreateRequestBody
): Promise<AxiosResponse> => {
  const Data = new FormData()
  Data.append('title', requestBody.title)
  Data.append('content', requestBody.content)
  if (requestBody.isNotifyAll) {
    // 全代理店に通知する場合、後日代理店が増えた場合に対応できないため代理店コードではなくフラグを保存する
    Data.append('isNotifyAll', '1')
  } else {
    // 個別の代理店を指定した場合、代理店コードを保存する
    Data.append('isNotifyAll', '0')
    requestBody.agencyCodeList.forEach((agencyCode, index) => {
      Data.append(`agencyCodeList[${index}]`, agencyCode)
    })
  }
  requestBody.files.forEach((file, index) => {
    Data.append(`files[${index}]`, file)
  })
  const headers = {
    'content-type': 'multipart/form-data',
  }
  return axios.get(`/api/csrf-cookie`).then(() => {
    const response = axios
      .post<{ data: NewsCreateResponse }>(`/api/news`, Data, {
        headers,
      })
      .catch((error) => {
        return error.response
      })
    return response
  })
}
