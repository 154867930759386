import { AxiosResponse } from 'axios'
import { ContractDetail } from 'src/types/contractDetail'

import axios from '../../axios'

export const fetchContract = async (
  id: string,
  agencyCode?: string
): Promise<AxiosResponse> => {
  const response = await axios
    .get<{ data: ContractDetail }>(`/api/contracts/${id}`, {
      params: {
        agencyCode: agencyCode,
      },
    })
    .catch((error) => {
      return error.response
    })

  return response
}
