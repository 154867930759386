import { AxiosResponse } from 'axios'
import { User } from 'src/types/user'

import axios from '../../axios'

export const verifyPhoneNumber = async (
  code: string
): Promise<AxiosResponse> => {
  await axios.get(`/api/csrf-cookie`)
  const response = await axios
    .post<{ user: User }>(`/api/verify-phone-number`, {
      code,
    })
    .catch((error) => {
      return error.response
    })

  return response
}
