import { AxiosResponse } from 'axios'

import axios from '../../axios'

export const cancel = async (
  managementNumber: string
): Promise<AxiosResponse> => {
  const response = await axios
    .post(`/api/contracts/hozen/cancellation`, {
      managementNumber,
    })
    .catch((error) => {
      return error.response
    })

  return response
}
