import { lazy, Suspense } from 'react'
import { Outlet, RouteObject } from 'react-router-dom'

import DashboardLayout from './components/dashboard/DashboardLayout'
import AgencySelectionGuard from './components/Guard/AgencySelectionGuard'
import AuthGuard from './components/Guard/AuthGuard'
import GuestGuard from './components/Guard/GuestGuard'
import RoleBasedGuard from './components/Guard/RoleBasedGuard'
import LoadingScreen from './components/LoadingScreen'
import { SearchStateChecker } from './components/SearchStateChecker'
import {
  OLIVE_ADMIN,
  OLIVE_GENERAL,
  OLIVE_SUPER_ADMIN,
  RECRUITER_ADMIN,
} from './types/roleType'

const Loadable = (Component: React.LazyExoticComponent<React.FC>) =>
  function loadable() {
    return (
      <Suspense fallback={<LoadingScreen />}>
        <Component />
      </Suspense>
    )
  }

// auth pages
const VerifyEmail = Loadable(
  lazy(() => import('./pages/authentication/VerifyEmail'))
)
const VerifyPhoneNumber = Loadable(
  lazy(() => import('./pages/authentication/VerifyPhoneNumber'))
)
const Login = Loadable(lazy(() => import('./pages/authentication/Login')))
const PasswordRecovery = Loadable(
  lazy(() => import('./pages/authentication/PasswordRecovery'))
)
const PasswordReset = Loadable(
  lazy(() => import('./pages/authentication/PasswordReset'))
)
const SmsAuthentication = Loadable(
  lazy(() => import('./pages/authentication/SmsAuthentication'))
)
const ChangeInitialPassword = Loadable(
  lazy(() => import('./pages/authentication/ChangeInitialPassword'))
)

// Dashboard pages

const Home = Loadable(lazy(() => import('./pages/dashboard/home/Home')))
const NewsRecruiterDetails = Loadable(
  lazy(() => import('./pages/dashboard/home/NewsRecruiterDetails'))
)
const ContractList = Loadable(
  lazy(() => import('./pages/dashboard/contract/ContractList'))
)
const ContractDetails = Loadable(
  lazy(() => import('./pages/dashboard/contract/ContractDetails'))
)
const RecruiterList = Loadable(
  lazy(() => import('./pages/dashboard/recruiter/RecruiterList'))
)
const RecruiterDetails = Loadable(
  lazy(() => import('./pages/dashboard/recruiter/RecruiterDetails'))
)
const RecruiterCreate = Loadable(
  lazy(() => import('./pages/dashboard/recruiter/RecruiterCreate'))
)
const FeeList = Loadable(lazy(() => import('./pages/dashboard/fee/FeeList')))
const AgencyList = Loadable(
  lazy(() => import('./pages/dashboard/agency/AgencyList'))
)
const OliveUserList = Loadable(
  lazy(() => import('./pages/dashboard/oliveUser/OliveUserList'))
)
const OliveUserDetails = Loadable(
  lazy(() => import('./pages/dashboard/oliveUser/OliveUserDetails'))
)
const OliveUserCreate = Loadable(
  lazy(() => import('./pages/dashboard/oliveUser/OliveUserCreate'))
)
const FileUpload = Loadable(
  lazy(() => import('./pages/dashboard/fileUpload/FileUpload'))
)
const LogList = Loadable(lazy(() => import('./pages/dashboard/log/LogList')))
const AccountSetting = Loadable(
  lazy(() => import('./pages/dashboard/account/AccountSetting'))
)
const ManualList = Loadable(
  lazy(() => import('./pages/dashboard/manual/ManualList'))
)
const NewsManagementList = Loadable(
  lazy(() => import('./pages/dashboard/news/NewsManagementList'))
)
const NewsCreate = Loadable(
  lazy(() => import('./pages/dashboard/news/NewsCreate'))
)
const NewsDetails = Loadable(
  lazy(() => import('./pages/dashboard/news/NewsDetails'))
)

// Error pages

const AuthorizationRequired = Loadable(
  lazy(() => import('./pages/AuthorizationRequired'))
)
const NotFound = Loadable(lazy(() => import('./pages/NotFound')))
const ServerError = Loadable(lazy(() => import('./pages/ServerError')))

const routes: RouteObject[] = [
  {
    path: 'authentication',
    element: (
      <GuestGuard>
        <Outlet />
      </GuestGuard>
    ),
    children: [
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'login-unguarded',
        element: <Login />,
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />,
      },
      {
        path: 'password-reset',
        element: <PasswordReset />,
      },
      {
        path: 'change-initial-password',
        element: <ChangeInitialPassword />,
      },
      {
        path: 'sms-authentication',
        element: <SmsAuthentication />,
      },
      {
        path: 'verify-email',
        element: <VerifyEmail />,
      },
      {
        path: 'verify-phone-number',
        element: <VerifyPhoneNumber />,
      },
    ],
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <SearchStateChecker>
          <DashboardLayout />
        </SearchStateChecker>
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: (
          <AgencySelectionGuard>
            <Home />
          </AgencySelectionGuard>
        ),
      },
      {
        path: 'news/:newsId',
        element: (
          <AgencySelectionGuard>
            <NewsRecruiterDetails />
          </AgencySelectionGuard>
        ),
      },
      {
        path: 'contracts',
        children: [
          {
            index: true,
            element: (
              <AgencySelectionGuard>
                <ContractList />
              </AgencySelectionGuard>
            ),
          },
          {
            path: ':contractId',
            element: (
              <AgencySelectionGuard>
                <ContractDetails />
              </AgencySelectionGuard>
            ),
          },
        ],
      },
      {
        path: 'fees',
        children: [
          {
            index: true,
            element: (
              <RoleBasedGuard
                roles={[OLIVE_SUPER_ADMIN, OLIVE_ADMIN, RECRUITER_ADMIN]}
              >
                <AgencySelectionGuard>
                  <FeeList />
                </AgencySelectionGuard>
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: 'account',
        children: [
          {
            index: true,
            element: <AccountSetting />,
          },
        ],
      },
      {
        path: 'manual',
        children: [
          {
            index: true,
            element: <ManualList />,
          },
        ],
      },
      {
        path: 'admin',
        children: [
          {
            path: 'agencies',
            children: [
              {
                index: true,
                element: (
                  <RoleBasedGuard
                    roles={[OLIVE_SUPER_ADMIN, OLIVE_ADMIN, OLIVE_GENERAL]}
                  >
                    <AgencyList />
                  </RoleBasedGuard>
                ),
              },
            ],
          },
          {
            path: 'olive-users',
            children: [
              {
                index: true,
                element: (
                  <RoleBasedGuard roles={[OLIVE_SUPER_ADMIN]}>
                    <OliveUserList />
                  </RoleBasedGuard>
                ),
              },
              {
                path: ':oliveUserId',
                element: (
                  <RoleBasedGuard roles={[OLIVE_SUPER_ADMIN]}>
                    <OliveUserDetails />
                  </RoleBasedGuard>
                ),
              },
              {
                path: 'new',
                element: (
                  <RoleBasedGuard roles={[OLIVE_SUPER_ADMIN]}>
                    <OliveUserCreate />
                  </RoleBasedGuard>
                ),
              },
            ],
          },
          {
            path: 'recruiters',
            children: [
              {
                index: true,
                element: (
                  <RoleBasedGuard roles={[OLIVE_SUPER_ADMIN]}>
                    <RecruiterList />
                  </RoleBasedGuard>
                ),
              },
              {
                path: ':recruiterId',
                element: (
                  <RoleBasedGuard roles={[OLIVE_SUPER_ADMIN]}>
                    <RecruiterDetails />
                  </RoleBasedGuard>
                ),
              },
              {
                path: 'new',
                element: (
                  <RoleBasedGuard roles={[OLIVE_SUPER_ADMIN]}>
                    <RecruiterCreate />
                  </RoleBasedGuard>
                ),
              },
            ],
          },
          {
            path: 'file-upload',
            children: [
              {
                index: true,
                element: (
                  <RoleBasedGuard roles={[OLIVE_SUPER_ADMIN]}>
                    <FileUpload />
                  </RoleBasedGuard>
                ),
              },
            ],
          },
          {
            path: 'logs',
            children: [
              {
                index: true,
                element: (
                  <RoleBasedGuard
                    roles={[OLIVE_SUPER_ADMIN, OLIVE_ADMIN, OLIVE_GENERAL]}
                  >
                    <LogList />
                  </RoleBasedGuard>
                ),
              },
            ],
          },
          {
            path: 'news',
            children: [
              {
                index: true,
                element: (
                  <RoleBasedGuard roles={[OLIVE_SUPER_ADMIN]}>
                    <NewsManagementList />
                  </RoleBasedGuard>
                ),
              },
              {
                path: 'new',
                element: (
                  <RoleBasedGuard roles={[OLIVE_SUPER_ADMIN]}>
                    <NewsCreate />
                  </RoleBasedGuard>
                ),
              },
              {
                path: ':newsId',
                element: (
                  <RoleBasedGuard roles={[OLIVE_SUPER_ADMIN]}>
                    <NewsDetails />
                  </RoleBasedGuard>
                ),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '*',
    children: [
      {
        path: '401',
        element: <AuthorizationRequired />,
      },
      {
        path: '404',
        element: <NotFound />,
      },
      {
        path: '500',
        element: <ServerError />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
]

export default routes
