import { createNews, NewsCreateRequestBody } from './createNews'
import { destroyNews } from './destroyNews'
import { downloadFile } from './downloadFile'
import { downloadFileRecruiter } from './downloadFileRecruiter'
import { fetchNews } from './fetchNews'
import { fetchNewsList } from './fetchNewsList'
import { fetchRecruiterNews } from './fetchRecruiterNews'
import { fetchRecruiterNewsList } from './fetchRecruiterNewsList'

export const news = () => {
  return {
    index(agencyCode: string | null, isAll: boolean) {
      return fetchNewsList(agencyCode, isAll)
    },
    post(requestBody: NewsCreateRequestBody) {
      return createNews(requestBody)
    },
    show(id: string) {
      return fetchNews(id)
    },
    downloadFile(newsId: string, filePath: string, agencyCode: string) {
      return downloadFile(newsId, filePath, agencyCode)
    },
    destroy(id: string) {
      return destroyNews(id)
    },
    findByAgencyCode(agencyCode: string) {
      return fetchRecruiterNewsList(agencyCode)
    },
    showRecruiter(id: string, agencyCode: string) {
      return fetchRecruiterNews(id, agencyCode)
    },
    downloadFileRecruiter(
      newsId: string,
      filePath: string,
      agencyCode: string
    ) {
      return downloadFileRecruiter(newsId, filePath, agencyCode)
    },
  }
}
