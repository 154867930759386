import { AxiosResponse } from 'axios'
import { ManualType } from 'src/types/manual'

import axios from '../../axios'

export const downloadManualZip = async (
  types: ManualType[]
): Promise<AxiosResponse> => {
  const response = await axios
    .get(`/api/manual/zip/download`, {
      params: { types },
      responseType: 'blob',
    })
    .catch((error) => {
      return error.response
    })

  return response
}
