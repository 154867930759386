import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAuth } from 'src/hooks/useAuth'
import { resetMessage, ValidationErrors } from 'src/store/slices/loading'

type Props = {
  errorMessage?: string
  errors?: ValidationErrors
}

export const ErrorDialog: FC<Props> = ({ errorMessage, errors }) => {
  const [open, setOpen] = useState<boolean>(!!errorMessage)
  const dispatch = useDispatch()
  const { logout } = useAuth()
  const unauthenticated = errorMessage === 'Unauthenticated.'

  const handleClose = async () => {
    dispatch(resetMessage())
    setOpen(false)

    if (unauthenticated) {
      await logout()
    }
  }

  useEffect(() => {
    setOpen(!!errorMessage)
  }, [errorMessage])

  const validationErrors = errors
    ? Object.entries(errors).flatMap(([_key, value]) => value)
    : null

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          color: (theme) => theme.palette.error.main,
          whiteSpace: 'pre-wrap',
        }}
      >
        {unauthenticated ? '再度ログインを行ってください。' : errorMessage}
      </DialogTitle>

      {errors && (
        <DialogContent>
          <List sx={{ pt: 0 }}>
            {validationErrors &&
              validationErrors.map((error) => (
                <ListItem key={error}>
                  <ListItemText primary={error} />
                </ListItem>
              ))}
          </List>
        </DialogContent>
      )}

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  )
}
