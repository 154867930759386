import { AxiosResponse } from 'axios'
import { ContractStatus } from 'src/types/contractStatus'

import axios from '../../axios'

export const fetchContractStatuses = async (): Promise<AxiosResponse> => {
  const response = await axios
    .get<{ data: ContractStatus[] }>('/api/statuses')
    .catch((error) => {
      return error.response
    })

  return response
}
