import { AxiosResponse } from 'axios'
import { HozenType } from 'src/components/dashboard/contract/Hozen'

import axios from '../../axios'

export type HozenChangePaymentMethodType = Extract<
  HozenType,
  'credit' | 'account'
>

export const changePaymentMethod = async (
  managementNumber: string,
  paymentMethod: HozenChangePaymentMethodType
): Promise<AxiosResponse> => {
  const response = await axios
    .post(`/api/contracts/hozen/change-payment-method`, {
      managementNumber,
      paymentMethod: paymentMethod === 'credit' ? '1' : '2',
    })
    .catch((error) => {
      return error.response
    })

  return response
}
