import { AxiosResponse } from 'axios'

import axios from '../../axios'

export const specificationsCsv = async (
  year: number,
  month: number,
  agencyCode?: string
): Promise<AxiosResponse> => {
  const response = await axios
    .get(`/api/commissions/specifications/csv/download`, {
      params: { agencyCode, year, month },
    })
    .catch((error) => {
      return error.response
    })

  return response
}
