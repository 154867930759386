import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ActionLogUser } from 'src/types/actionLog'

export type ActionLogUsersState = {
  actionLogUsers: ActionLogUser[]
}

export const initialActionLogUsersState: ActionLogUsersState = {
  actionLogUsers: [],
}

export const actionLogUsersSlice = createSlice({
  name: 'actionLogUsers',
  initialState: initialActionLogUsersState,
  reducers: {
    setActionLogUsers: (
      state: ActionLogUsersState,
      action: PayloadAction<ActionLogUser[]>
    ) => ({
      ...state,
      actionLogUsers: action.payload,
    }),
  },
})

export const { setActionLogUsers } = actionLogUsersSlice.actions
