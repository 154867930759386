import { ThemeProvider } from '@material-ui/core'
import { LocalizationProvider } from '@material-ui/lab'
import AdapterDateFns from '@material-ui/lab/AdapterDateFns'
import jaLocale from 'date-fns/locale/ja'
import { SnackbarProvider } from 'notistack'
import type { FC } from 'react'
import { useEffect } from 'react'
import { useRoutes } from 'react-router-dom'

import { AuthProvider } from './components/AuthProvider'
import GlobalStyles from './components/GlobalStyles'
import SplashScreen from './components/SplashScreen'
import { gtmConfig } from './config'
import { THEMES } from './constants'
import { useAuth } from './hooks/useAuth'
import useScrollReset from './hooks/useScrollReset'
import gtm from './lib/gtm'
import routes from './routes'
import { createTheme } from './theme'
import { initYupLocale } from './yup.locale'

const App: FC = () => {
  initYupLocale()
  const content = useRoutes(routes)
  const { isInitialized } = useAuth()
  useScrollReset()

  useEffect(() => {
    gtm.initialize(gtmConfig)
  }, [])

  const theme = createTheme({
    responsiveFontSizes: true,
    roundedCorners: true,
    theme: THEMES.LIGHT,
  })

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
        <SnackbarProvider dense maxSnack={3}>
          <GlobalStyles />
          <AuthProvider>
            {isInitialized ? content : <SplashScreen />}
          </AuthProvider>
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App
