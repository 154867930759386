import { Button, Tooltip } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import type { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import UserIcon from '../../icons/User'

const ContactsPopover: FC = () => {
  const navigate = useNavigate()

  const handleClick = (): void => {
    navigate('/account')
  }
  const theme = useTheme()
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      <Tooltip title="アカウント設定">
        <Button
          sx={{ color: 'text.secondary' }}
          variant="text"
          startIcon={<UserIcon />}
          onClick={handleClick}
        >
          {isMobileDevice ? '' : 'アカウント設定'}
        </Button>
      </Tooltip>
    </>
  )
}

export default ContactsPopover
