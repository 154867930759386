import { AxiosResponse } from 'axios'
import { User } from 'src/types/user'

import axios from '../../axios'

export const changeInitialPassword = async (
  email: string,
  password: string,
  session: string
): Promise<AxiosResponse> => {
  await axios.get(`/api/csrf-cookie`)
  const response = await axios
    .post<{ user: User }>(`/api/change-initial-password`, {
      email,
      password,
      session,
    })
    .catch((error) => {
      return error.response
    })

  return response
}
