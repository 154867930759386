import { AxiosResponse } from 'axios'
import { User } from 'src/types/user'

import axios from '../../axios'

export const sendVerificationCodeToEmail = async (): Promise<AxiosResponse> => {
  const isEmail = true

  await axios.get(`/api/csrf-cookie`)
  const response = await axios
    .post<{ user: User }>(`/api/send-verification-code`, {
      isEmail,
    })
    .catch((error) => {
      return error.response
    })

  return response
}
