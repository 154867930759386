import {
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  makeStyles,
  Stack,
  Typography,
} from '@material-ui/core'
import ReceiptIcon from '@material-ui/icons/Receipt'
import ReplyIcon from '@material-ui/icons/Reply'
import { CombinedState } from '@reduxjs/toolkit'
import type { FC } from 'react'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from 'src/hooks/useAuth'
import useLoginUserStatus from 'src/hooks/useLoginUserStatus'
import ChartSquareBarIcon from 'src/icons/ChartSquareBar'
import DomainIcon from 'src/icons/Domain'
import HomeIcon from 'src/icons/Home'
import NewsIcon from 'src/icons/News'
import TraceIcon from 'src/icons/Trace'
import UploadIcon from 'src/icons/Upload'
import UsersIcon from 'src/icons/Users'
import { useDispatch, useSelector } from 'src/store'
import { AgenciesState } from 'src/store/slices/agencies'
import { setRecruiters } from 'src/store/slices/recruiters'
import {
  isOliveUserRole,
  OLIVE_ADMIN,
  OLIVE_GENERAL,
  OLIVE_SUPER_ADMIN,
  RECRUITER_ADMIN,
  RECRUITER_GENERAL,
  RoleType,
} from 'src/types/roleType'
import { LOGIN_USER_STATUS, LoginUserStatus } from 'src/types/user'

import Label, { LabelColor } from '../Label'
import NavSection from '../NavSection'
import Scrollbar from '../Scrollbar'

type DashboardSidebarProps = {
  onMobileClose: () => void
  openMobile: boolean
}

const useStyles = makeStyles({
  logo: {
    display: 'block',
  },
})

type Item = {
  title: string
  path: string
  icon: JSX.Element
}

type Section = {
  title: string
  items: Item[]
}

type SectionItems = {
  [key: string]: Item
}

type LoginUserMenuItems = {
  [key in LoginUserStatus]: {
    generalSection?: Item[]
    managementSection?: Item[]
  }
}

// サイドメニューの項目一覧
const menuItems: SectionItems = {
  home: {
    title: 'ホーム',
    path: '/',
    icon: <HomeIcon fontSize="small" />,
  },
  contracts: {
    title: '契約内容一覧',
    path: '/contracts',
    icon: <ChartSquareBarIcon fontSize="small" />,
  },
  fees: {
    title: '手数料等',
    path: '/fees',
    icon: <ReceiptIcon fontSize="small" />,
  },
  recruiters: {
    title: '募集人一覧',
    path: '/admin/recruiters',
    icon: <UsersIcon fontSize="small" />,
  },
  agencies: {
    title: '代理店一覧',
    path: '/admin/agencies',
    icon: <DomainIcon fontSize="small" />,
  },
  oliveUsers: {
    title: 'オリーブユーザー一覧',
    path: '/admin/olive-users',
    icon: <UsersIcon fontSize="small" />,
  },
  fileUpload: {
    title: 'ファイルアップロード',
    path: '/admin/file-upload',
    icon: <UploadIcon fontSize="small" />,
  },
  logs: {
    title: '証跡一覧',
    path: '/admin/logs',
    icon: <TraceIcon fontSize="small" />,
  },
  news: {
    title: 'お知らせ一覧',
    path: '/admin/news',
    icon: <NewsIcon fontSize="small" />,
  },
}

// ログインユーザーによってサイドメニューの項目が異なるためオブジェクトで定義している
const loginUserMenuItems: LoginUserMenuItems = {
  [LOGIN_USER_STATUS.OLIVE_SUPER_ADMIN_SELECTED_AGENCY]: {
    generalSection: [menuItems.home, menuItems.contracts, menuItems.fees],
    managementSection: [
      menuItems.oliveUsers,
      menuItems.recruiters,
      menuItems.fileUpload,
      menuItems.logs,
      menuItems.news,
    ],
  },
  [LOGIN_USER_STATUS.OLIVE_ADMIN_SELECTED_AGENCY]: {
    generalSection: [menuItems.home, menuItems.contracts, menuItems.fees],
    managementSection: [menuItems.logs],
  },
  [LOGIN_USER_STATUS.OLIVE_GENERAL_SELECTED_AGENCY]: {
    generalSection: [menuItems.home, menuItems.contracts],
    managementSection: [menuItems.logs],
  },
  [LOGIN_USER_STATUS.OLIVE_SUPER_ADMIN]: {
    managementSection: [
      menuItems.agencies,
      menuItems.oliveUsers,
      menuItems.recruiters,
      menuItems.fileUpload,
      menuItems.logs,
      menuItems.news,
    ],
  },
  [LOGIN_USER_STATUS.OLIVE_ADMIN]: {
    managementSection: [menuItems.agencies, menuItems.logs],
  },
  [LOGIN_USER_STATUS.OLIVE_GENERAL]: {
    managementSection: [menuItems.agencies, menuItems.logs],
  },
  [LOGIN_USER_STATUS.RECRUITER_ADMIN]: {
    generalSection: [menuItems.home, menuItems.contracts, menuItems.fees],
  },
  [LOGIN_USER_STATUS.RECRUITER_GENERAL]: {
    generalSection: [menuItems.home, menuItems.contracts],
  },
}

const userDisplayInfo: {
  [key in RoleType]: {
    label: string
    color: LabelColor
  }
} = {
  [OLIVE_SUPER_ADMIN]: {
    label: '特権管理者',
    color: 'warning',
  },
  [OLIVE_ADMIN]: {
    label: '管理者',
    color: 'success',
  },
  [OLIVE_GENERAL]: {
    label: '一般',
    color: 'primary',
  },
  [RECRUITER_ADMIN]: {
    label: '管理者',
    color: 'success',
  },
  [RECRUITER_GENERAL]: {
    label: '一般',
    color: 'primary',
  },
}

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props
  const classes = useStyles()
  const location = useLocation()
  const navigate = useNavigate()
  const { user, updateAgencyCode } = useAuth()
  const dispatch = useDispatch()
  const loginUserStatus = useLoginUserStatus()
  const { agencies } = useSelector(
    (state: CombinedState<{ agencies: AgenciesState }>) => state.agencies
  )

  const items = loginUserStatus ? loginUserMenuItems[loginUserStatus] : {}
  const sections: Section[] = []

  if (items['generalSection']) {
    sections.push({
      title: 'General',
      items: items['generalSection'],
    })
  }
  if (items['managementSection']) {
    sections.push({
      title: 'Management',
      items: items['managementSection'],
    })
  }

  const handleReselectionAgency = () => {
    updateAgencyCode(undefined)
    dispatch(setRecruiters([]))
    navigate('/admin/agencies')
  }

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              p: 2,
            }}
          >
            <RouterLink to="/">
              <img
                src="/static/logo.png"
                alt="logo"
                height="48"
                className={classes.logo}
              />
            </RouterLink>
          </Box>
        </Hidden>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              p: 2,
            }}
          >
            <Stack
              gap={1}
              sx={{
                textAlign: 'start',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography color="textSecondary" variant="caption">
                  最終ログイン
                </Typography>
                <Typography color="textSecondary" variant="caption">
                  {user?.lastLoginAt}
                </Typography>
              </Box>
              {user && (
                <Box>
                  <Label color={userDisplayInfo[user.roleType].color}>
                    {userDisplayInfo[user.roleType].label}
                  </Label>
                </Box>
              )}
              <Box sx={{ wordBreak: 'break-all' }}>
                <Typography color="textPrimary" variant="subtitle2">
                  {user?.name}
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  {user?.email}
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Box>
        <Divider />
        {user && isOliveUserRole(user.roleType) && user.agencyCode && (
          <>
            {agencies.map((agency) => {
              if (agency.agencyCode === user.agencyCode) {
                return (
                  <Box
                    key={agency.agencyCode}
                    sx={{
                      p: 2,
                      pb: 0,
                    }}
                  >
                    <Typography
                      sx={{
                        color: 'text.primary',
                        fontSize: '0.75rem',
                        lineHeight: 2.5,
                        fontWeight: 700,
                        textTransform: 'uppercase',
                        pb: 1,
                      }}
                    >
                      選択中の代理店
                    </Typography>
                    <Typography variant="caption" gutterBottom component="div">
                      {agency.name}
                    </Typography>
                  </Box>
                )
              }
            })}
            <Box
              sx={{
                p: 2,
              }}
            >
              <Button
                color="primary"
                onClick={() => handleReselectionAgency()}
                type="button"
                variant="contained"
                startIcon={<ReplyIcon />}
                sx={{ width: '100%' }}
              >
                代理店を選択し直す
              </Button>
            </Box>
            <Divider />
          </>
        )}
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))}
        </Box>
      </Scrollbar>
    </Box>
  )

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              width: 280,
            },
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              height: 'calc(100% - 64px) !important',
              top: '64px !Important',
              width: 280,
            },
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  )
}

export default DashboardSidebar
