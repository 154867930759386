import { AxiosResponse } from 'axios'

import axios from '../../axios'

export const downloadFileRecruiter = async (
  newsId: string,
  filePath: string,
  agencyCode: string
): Promise<AxiosResponse> => {
  const response = await axios
    .get(`/api/recruiter-news/post/file/download`, {
      params: { newsId, filePath, agencyCode },
    })
    .catch((error) => {
      return error.response
    })

  return response
}
