import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SearchCondition } from 'src/types/searchCondition'

export type SearchConditionsState = {
  searchConditions: SearchCondition
}

export const initialSearchConditionsState: SearchConditionsState = {
  searchConditions: {
    currentPage: 1,
    query: '',
    selectedStatuses: [],
    selectedHikisariStatuses: [],
    startFirstContractDate: undefined,
    endFirstContractDate: undefined,
    startFirstPaymentDate: undefined,
    endFirstPaymentDate: undefined,
  },
}

export const searchConditionsSlice = createSlice({
  name: 'searchConditions',
  initialState: initialSearchConditionsState,
  reducers: {
    // redux-toolkitではDate型をシリアライズできないためstringで保存させる
    setSearchConditions: (
      state: SearchConditionsState,
      action: PayloadAction<SearchCondition>
    ) => ({
      ...state,
      searchConditions: action.payload,
    }),
    resetSearchConditions: () => initialSearchConditionsState,
  },
})

export const { setSearchConditions, resetSearchConditions } =
  searchConditionsSlice.actions
