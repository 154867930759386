import * as Yup from 'yup'
import { MessageParams } from 'yup/lib/types'

const labelText = (prm: MessageParams) => {
  return prm.label ? `${prm.label}は` : ''
}

const jpConfig = {
  mixed: {
    default: (prm: MessageParams) => `${labelText(prm)}無効です。`,
    required: (prm: MessageParams) => `${labelText(prm)}必ず指定してください。`,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    oneOf: (prm: MessageParams & { values: any }) =>
      `${labelText(prm)}次の値のいずれかでなければなりません:${prm.values}`,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    notOneOf: (prm: MessageParams & { values: any }) =>
      `${labelText(prm)}次の値のいずれかであってはなりません:${prm.values}`,
    notType: `形式が違います。`,
    defined: ``,
  },
  string: {
    length: (prm: MessageParams & { length: number }) =>
      `${labelText(prm)}${prm.length}文字で指定してください。`,
    min: (prm: MessageParams & { min: number }) =>
      `${labelText(prm)}少なくとも${prm.min}文字以上で指定してください。`,
    max: (prm: MessageParams & { max: number }) =>
      `${labelText(prm)}最大${prm.max}文字以下で指定してください。`,
    matches: (prm: MessageParams & { regex: RegExp }) =>
      `${labelText(prm)}次の形式と一致する必要があります: "${prm.regex}"`,
    email: (prm: MessageParams & { regex: RegExp }) =>
      `${labelText(prm)}有効なメールアドレスを指定してください。`,
    url: (prm: MessageParams & { regex: RegExp }) =>
      `${labelText(prm)}正しい形式を指定してください。`,
    uuid: (prm: MessageParams & { regex: RegExp }) =>
      `${labelText(prm)}有効なUUIDを指定してください。`,
    trim: (prm: MessageParams) =>
      `${labelText(prm)}前後にスペースを入れないでください。`,
    lowercase: (prm: MessageParams) =>
      `${labelText(prm)}小文字を指定してください。`,
    uppercase: (prm: MessageParams) =>
      `${labelText(prm)}大文字を指定してください。`,
  },
  number: {
    min: (prm: MessageParams & { min: number }) =>
      `${labelText(prm)}${prm.min}以上の数字を指定してください。`,
    max: (prm: MessageParams & { max: number }) =>
      `${labelText(prm)}${prm.max}以下の数字を指定してください。`,
    lessThan: (prm: MessageParams & { less: number }) =>
      `${labelText(prm)}${prm.less}より小さい数字を指定してください。`,
    moreThan: (prm: MessageParams & { more: number }) =>
      `${labelText(prm)}${prm.more}より大きい数字を指定してください。`,
    positive: (prm: MessageParams & { more: number }) =>
      `${labelText(prm)}正の数を指定してください。`,
    negative: (prm: MessageParams & { less: number }) =>
      `${labelText(prm)}負の数を指定してください。`,
    integer: (prm: MessageParams) =>
      `${labelText(prm)}整数を指定してください。`,
  },
  date: {
    min: (prm: MessageParams & { min: Date | string }) =>
      `${labelText(prm)}${prm.min}より後の日付を指定してください。`,
    max: (prm: MessageParams & { max: Date | string }) =>
      `${labelText(prm)}${prm.max}より前の日付を指定してください。`,
  },
  boolean: {
    isValue: (prm: MessageParams) => `${labelText(prm)}を指定してください。`,
  },
  object: {
    noUnknown: (prm: MessageParams) =>
      `${labelText(
        prm
      )}オブジェクトシェイプで指定されていないキーを含めることはできません。`,
  },
  array: {
    length: (prm: MessageParams & { length: number }) =>
      `${labelText(prm)}${prm.length}個指定してください。`,
    min: (prm: MessageParams & { min: number }) =>
      `${labelText(prm)}${prm.min}個以上指定してください。`,
    max: (prm: MessageParams & { max: number }) =>
      `${labelText(prm)}${prm.max}個以下指定してください。`,
  },
}

Yup.setLocale(jpConfig)

export const initYupLocale = () => undefined
