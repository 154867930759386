import { AxiosResponse } from 'axios'
import { User } from 'src/types/user'

import axios from '../../axios'

export const me = async (): Promise<AxiosResponse> => {
  const response = await axios.get<{ user: User }>(`/api/me`).catch((error) => {
    return error.response
  })

  return response
}
