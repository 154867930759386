import { AxiosResponse } from 'axios'
import { ManualList } from 'src/types/manual'

import axios from '../../axios'

export const fetchManualList = async (): Promise<AxiosResponse> => {
  const response = await axios
    .get<{ data: ManualList[] }>('/api/manual')
    .catch((error) => {
      return error.response
    })

  return response
}
