import axios from 'src/lib/axios'
import { ActionLogUser } from 'src/types/actionLog'

export const actionLogUsers = async () => {
  const response = await axios
    .get<{ data: ActionLogUser[] }>('/api/action-logs/actionLogUsers', {})
    .catch((error) => {
      return error.response
    })

  return response
}
