import { Button, Tooltip } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import type { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import DocumentTextIcon from '../../icons/DocumentText'

const ManualButton: FC = () => {
  const navigate = useNavigate()

  const handleClick = (): void => {
    navigate('/manual')
  }
  const theme = useTheme()
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Tooltip title="マニュアル">
      <Button
        sx={{ color: 'text.secondary' }}
        variant="text"
        startIcon={<DocumentTextIcon />}
        onClick={handleClick}
      >
        {isMobileDevice ? '' : '各種マニュアル'}
      </Button>
    </Tooltip>
  )
}

export default ManualButton
