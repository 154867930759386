import { FC, ReactNode, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { resetSearchConditions } from 'src/store/slices/searchConditions'

type Props = {
  children: ReactNode
}

// 契約一覧画面と契約詳細画面以外のページへ遷移した場合は検索条件のstoreをリセットする
export const SearchStateChecker: FC<Props> = (props) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const currentPathName = location.pathname

  useEffect(() => {
    const isReset = !currentPathName.includes('contracts')

    if (isReset) {
      dispatch(resetSearchConditions())
    }
  }, [currentPathName, dispatch])

  return <>{props.children}</>
}
