import { AxiosResponse } from 'axios'
import { format } from 'date-fns'
import { ContractStatusCode } from 'src/types/contractStatus'
import { HikisariStatusCode } from 'src/types/hikisari'

import axios from '../../axios'

export const csvExport = async (
  agencyCode?: string,
  keyword?: string,
  statuses?: ContractStatusCode[],
  selectedHikisariStatuses?: HikisariStatusCode[],
  startFirstContractDate?: Date,
  endFirstContractDate?: Date,
  startFirstPaymentDate?: Date,
  endFirstPaymentDate?: Date
): Promise<AxiosResponse> => {
  const response = await axios
    .get('/api/contractsExport', {
      params: {
        agencyCode: agencyCode,
        keyword: keyword ?? '',
        statuses: statuses ?? [],
        selectedHikisariStatuses: selectedHikisariStatuses ?? [],
        startFirstContractDate: startFirstContractDate
          ? format(new Date(startFirstContractDate), 'yyyy/MM/dd HH:mm')
          : null,
        endFirstContractDate: endFirstContractDate
          ? format(new Date(endFirstContractDate), 'yyyy/MM/dd HH:mm')
          : null,
        startFirstPaymentDate: startFirstPaymentDate
          ? format(new Date(startFirstPaymentDate), 'yyyy/MM/dd HH:mm')
          : null,
        endFirstPaymentDate: endFirstPaymentDate
          ? format(new Date(endFirstPaymentDate), 'yyyy/MM/dd HH:mm')
          : null,
      },
      responseType: 'blob',
    })
    .catch((error) => {
      return error.response
    })

  return response
}
