import { FC, ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from 'src/hooks/useAuth'

interface AgencySelectionGuardProps {
  children: ReactNode
}

const AgencySelectionGuard: FC<AgencySelectionGuardProps> = ({ children }) => {
  const { user } = useAuth()

  if (user?.agencyCode) {
    return <>{children}</>
  }

  return <Navigate to={'/admin/agencies'} replace={true} />
}

export default AgencySelectionGuard
