import { AxiosResponse } from 'axios'
import { NewsList } from 'src/types/news'

import axios from '../../axios'

export const fetchRecruiterNewsList = async (
  agencyCode: string
): Promise<AxiosResponse> => {
  const response = await axios
    .get<{ data: NewsList }>('/api/recruiter-news', {
      params: {
        agencyCode,
      },
    })
    .catch((error) => {
      return error.response
    })

  return response
}
