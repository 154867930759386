import { actionLogUsers } from './actionLogUsers'
import { fetchActionLogs } from './fetchActionLogs'

export const actionLogs = {
  index(agencyCode: string, userId?: number, page?: number) {
    return fetchActionLogs(agencyCode, userId, page)
  },
  actionLogUsers() {
    return actionLogUsers()
  },
}
