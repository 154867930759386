import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ContractStatus } from 'src/types/contractStatus'

export type ContractStatusesState = {
  contractStatuses: ContractStatus[]
}

export const initialContractStatusesState: ContractStatusesState = {
  contractStatuses: [],
}

export const contractStatusesSlice = createSlice({
  name: 'contractStatuses',
  initialState: initialContractStatusesState,
  reducers: {
    setContractStatuses: (
      state: ContractStatusesState,
      action: PayloadAction<ContractStatus[]>
    ) => ({
      ...state,
      contractStatuses: action.payload,
    }),
  },
})

export const { setContractStatuses } = contractStatusesSlice.actions
