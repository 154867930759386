import { ManualType } from 'src/types/manual'

import { downloadManual } from './downloadManual'
import { downloadManualZip } from './downloadManualZip'
import { fetchManualList } from './fetchManualList'

export const manual = () => {
  return {
    index() {
      return fetchManualList()
    },
    downloadManual(filePath: string) {
      return downloadManual(filePath)
    },
    downloadManualZip(types: ManualType[]) {
      return downloadManualZip(types)
    },
  }
}
