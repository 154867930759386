import { AxiosResponse } from 'axios'

import axios from '../../axios'

export const forgotPassword = async (email: string): Promise<AxiosResponse> => {
  return axios.get(`/api/csrf-cookie`).then(() => {
    const response = axios
      .post(`/api/forgot-password`, {
        email,
      })
      .catch((error) => {
        return error.response
      })

    return response
  })
}
