import { AxiosResponse } from 'axios'
import { Recruiter } from 'src/types/recruiter'

import axios from '../../axios'

export const fetchRecruiter = async (
  id: string,
  agencyCode?: string
): Promise<AxiosResponse> => {
  const response = await axios
    .get<{ data: Recruiter }>(`/api/recruiters/${id}`, {
      params: { agencyCode: agencyCode },
    })
    .catch((error) => {
      return error.response
    })

  return response
}
