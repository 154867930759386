import { AxiosResponse } from 'axios'
import { Recruiter } from 'src/types/recruiter'

import axios from '../../axios'

export const fetchRecruiters = async (): Promise<AxiosResponse> => {
  const response = await axios
    .get<{ data: Recruiter[] }>('/api/recruiters', {})
    .catch((error) => {
      return error.response
    })

  return response
}
