import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Agency } from 'src/types/agency'

export type AgenciesState = {
  agencies: Agency[]
}

export const initialAgenciesState: AgenciesState = {
  agencies: [],
}

export const agenciesSlice = createSlice({
  name: 'agencies',
  initialState: initialAgenciesState,
  reducers: {
    setAgencies: (state: AgenciesState, action: PayloadAction<Agency[]>) => ({
      ...state,
      agencies: action.payload,
    }),
  },
})

export const { setAgencies } = agenciesSlice.actions
