import { changeInitialPassword } from './changeInitialPassword'
import { forgotPassword } from './forgotPassword'
import { login } from './login'
import { logout } from './logout'
import { me } from './me'
import { passwordChange, PasswordChangeRequestBody } from './passwordChange'
import { resendInitialPassword } from './resendInitialPasswordRequest'
import { resetPassword } from './resetPassword'
import { sendVerificationCodeToEmail } from './sendVerificationCodeToEmail'
import { sendVerificationCodeToSms } from './sendVerificationCodeToSms'
import { smsAuthentication } from './smsAuthentication'
import { verifyEmail } from './verifyEmail'
import { verifyPhoneNumber } from './verifyPhoneNumber'

export const auth = {
  forgotPassword(email: string) {
    return forgotPassword(email)
  },
  login(email: string, password: string) {
    return login(email, password)
  },
  logout() {
    return logout()
  },
  me() {
    return me()
  },
  passwordChange(requestBody: PasswordChangeRequestBody) {
    return passwordChange(requestBody)
  },
  resetPassword(email: string, password: string, code: string) {
    return resetPassword(email, password, code)
  },
  changeInitialPassword(email: string, password: string, session: string) {
    return changeInitialPassword(email, password, session)
  },
  smsAuthentication(
    email: string,
    smsVerificationCode: string,
    session: string
  ) {
    return smsAuthentication(email, smsVerificationCode, session)
  },
  verifyEmail(code: string) {
    return verifyEmail(code)
  },
  verifyPhoneNumber(code: string) {
    return verifyPhoneNumber(code)
  },
  sendVerificationCodeToEmail() {
    return sendVerificationCodeToEmail()
  },
  sendVerificationCodeToSms() {
    return sendVerificationCodeToSms()
  },
  resendInitialPassword(email: string) {
    return resendInitialPassword(email)
  },
}
