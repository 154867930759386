import { AxiosResponse } from 'axios'

import axios from '../../axios'

export const resetPassword = async (
  email: string,
  password: string,
  code: string
): Promise<AxiosResponse> => {
  return axios.get(`/api/csrf-cookie`).then(() => {
    const response = axios
      .post(`/api/reset-password`, {
        email,
        password,
        code,
      })
      .catch((error) => {
        return error.response
      })

    return response
  })
}
