import axios from 'axios'

axios.defaults.withCredentials = true
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_ENDPOINT,
  headers: {
    Accept: 'application/json',
  },
})

export default axiosInstance
