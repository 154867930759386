import { createRecruiter, RecruiterCreateRequestBody } from './createRecruiter'
import { destroyRecruiter } from './destroyRecruiter'
import { downloadRecruiterList } from './downloadRecruiterList'
import { fetchRecruiter } from './fetchRecruiter'
import { fetchRecruiters } from './fetchRecruiters'
import { RecruiterUpdateRequestBody, updateRecruiter } from './updateRecruiter'

export const recruiters = () => {
  return {
    index() {
      return fetchRecruiters()
    },
    show(id: string, agencyCode?: string) {
      return fetchRecruiter(id, agencyCode)
    },
    post(requestBody: RecruiterCreateRequestBody) {
      return createRecruiter(requestBody)
    },
    put(id: string, requestBody: RecruiterUpdateRequestBody) {
      return updateRecruiter(id, requestBody)
    },
    destroy(id: string, agencyCode?: string) {
      return destroyRecruiter(id, agencyCode)
    },
    download(agencyCodes: string[]) {
      return downloadRecruiterList(agencyCodes)
    },
  }
}
