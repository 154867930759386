import { AxiosResponse } from 'axios'
import { User } from 'src/types/user'

import axios from '../../axios'

export const login = async (
  email: string,
  password: string
): Promise<AxiosResponse> => {
  await axios.get(`/api/csrf-cookie`)
  const response = await axios
    // TODO: backendでの認証が完了したら切り替える
    // .post<{ user: User }>(`/api/cognito-login`, {
    .post<{ user: User }>(`/login`, {
      email,
      password,
    })
    .catch((error) => {
      return error.response
    })

  return response
}
