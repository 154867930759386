import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { User } from 'src/types/user'

export type AuthState = {
  isInitialized: boolean
  isAuthenticated: boolean
  user: User | null
}

type InitializeAuth = Pick<AuthState, 'isAuthenticated' | 'user'>

export const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
}

// 全通信でcookieにtokenを保存させ、送信時にcookieを渡す設定
axios.defaults.withCredentials = true

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    initialize: (state: AuthState, action: PayloadAction<InitializeAuth>) => {
      const { isAuthenticated, user } = action.payload

      return {
        ...state,
        isAuthenticated,
        isInitialized: true,
        user,
      }
    },
    loginAction: (state: AuthState, action: PayloadAction<User>) => {
      const user = action.payload

      return {
        ...state,
        isAuthenticated: true,
        user,
      }
    },
    logoutAction: (state: AuthState) => ({
      ...state,
      isAuthenticated: false,
      isInitialized: true,
      user: null,
    }),
    smsAuthenticationAction: (
      state: AuthState,
      action: PayloadAction<User>
    ) => {
      const user = action.payload

      return {
        ...state,
        isAuthenticated: true,
        user,
      }
    },
    changeInitialPasswordAction: (
      state: AuthState,
      action: PayloadAction<User>
    ) => {
      const user = action.payload

      return {
        ...state,
        isAuthenticated: true,
        user,
      }
    },
    successfullyVerifiedAction: (
      state: AuthState,
      action: PayloadAction<User>
    ) => {
      const user = action.payload

      return {
        ...state,
        isAuthenticated: true,
        user,
      }
    },
    updateAgencyCodeAction: (
      state: AuthState,
      action: PayloadAction<string | undefined>
    ) => {
      const agencyCode = action.payload

      if (state.user) {
        state.user.agencyCode = agencyCode
      }
    },
  },
})

export const {
  initialize,
  loginAction,
  logoutAction,
  smsAuthenticationAction,
  changeInitialPasswordAction,
  updateAgencyCodeAction,
  successfullyVerifiedAction,
} = authSlice.actions

export const updateToAgencyCodeInSessionStorage = (oldUser: User): User => {
  const storageAgencyCode = sessionStorage.getItem('agencyCode')

  if (storageAgencyCode) {
    const newUser: User = { ...oldUser, agencyCode: storageAgencyCode }
    return newUser
  }

  return oldUser
}
