import { FC, ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import { RoleType } from 'src/types/roleType'

import { useAuth } from '../../hooks/useAuth'

interface RoleBasedGuardProps {
  children: ReactNode
  roles: RoleType[]
}

const RoleBasedGuard: FC<RoleBasedGuardProps> = ({ children, roles }) => {
  const { user } = useAuth()

  if (user && roles.includes(user.roleType)) {
    return <>{children}</>
  }

  return <Navigate to={'/404'} replace={true} />
}

export default RoleBasedGuard
