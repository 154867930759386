import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type ValidationErrors = {
  [key in string]: string[]
}

export type LoadingState = {
  isLoaded: boolean
  errorMessage?: string
  errors?: ValidationErrors
}

type ErrorMessageType = {
  errorMessage?: string
  errors?: ValidationErrors
}

export const initialLoadingState: LoadingState = {
  isLoaded: false,
  errorMessage: undefined,
  errors: undefined,
}

export const loadingSlice = createSlice({
  name: 'loading',
  initialState: initialLoadingState,
  reducers: {
    // 通信を開始時
    fetchStart: () => ({
      isLoaded: true,
      errorMessage: undefined,
      errors: undefined,
    }),
    // 通信が失敗時
    fetchFailure: (_state, action: PayloadAction<ErrorMessageType>) => ({
      isLoaded: false,
      errorMessage: action.payload.errorMessage,
      errors: action.payload.errors,
    }),
    // 通信が成功時
    fetchSuccess: () => ({
      isLoaded: false,
      errorMessage: undefined,
      errors: undefined,
    }),
    resetMessage: () => ({
      isLoaded: false,
      errorMessage: undefined,
      errors: undefined,
    }),
  },
})

export const { fetchStart, fetchFailure, fetchSuccess, resetMessage } =
  loadingSlice.actions
