import { AxiosResponse } from 'axios'
import { NewsDetail } from 'src/types/news'

import axios from '../../axios'

export const fetchRecruiterNews = async (
  id: string,
  agencyCode: string
): Promise<AxiosResponse> => {
  const response = await axios
    .get<{ data: NewsDetail }>(`/api/recruiter-news/${id}`, {
      params: {
        agencyCode,
      },
    })
    .catch((error) => {
      return error.response
    })

  return response
}
