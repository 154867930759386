import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Recruiter } from 'src/types/recruiter'

export type RecruitersState = {
  recruiters: Recruiter[]
}

export const initialRecruitersState: RecruitersState = {
  recruiters: [],
}

export const recruitersSlice = createSlice({
  name: 'recruiters',
  initialState: initialRecruitersState,
  reducers: {
    setRecruiters: (
      state: RecruitersState,
      action: PayloadAction<Recruiter[]>
    ) => ({
      ...state,
      recruiters: action.payload,
    }),
    updateRecruiter: (
      state: RecruitersState,
      action: PayloadAction<Recruiter>
    ) => ({
      ...state,
      recruiters: state.recruiters.map((recruiter) =>
        recruiter.id === action.payload.id ? action.payload : recruiter
      ),
    }),
    createRecruiter: (
      state: RecruitersState,
      action: PayloadAction<Recruiter>
    ) => {
      state.recruiters.push(action.payload)
    },
    deleteRecruiter: (
      state: RecruitersState,
      action: PayloadAction<string>
    ) => ({
      ...state,
      recruiters: state.recruiters.filter(
        (recruiter) => recruiter.id !== action.payload
      ),
    }),
  },
})

export const {
  setRecruiters,
  updateRecruiter,
  createRecruiter,
  deleteRecruiter,
} = recruitersSlice.actions
