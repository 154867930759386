import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ManualList } from 'src/types/manual'

export type ManualState = {
  manualList: ManualList
}

export const initialManualState: ManualState = {
  manualList: {
    agency: [],
    product: [],
    officeProcedure: [],
    compliance: [],
  },
}

export const manualSlice = createSlice({
  name: 'agencies',
  initialState: initialManualState,
  reducers: {
    setManualList: (
      state: { manualList: ManualList },
      action: PayloadAction<ManualList>
    ) => ({
      manualList: {
        ...state.manualList,
        agency: action.payload.agency,
        product: action.payload.product,
        officeProcedure: action.payload.officeProcedure,
        compliance: action.payload.compliance,
      },
    }),
  },
})

export const { setManualList } = manualSlice.actions
