import { settlementsPdf } from './settlementsPdf'
import { specificationsCsv } from './specificationsCsv'
import { specificationsPdf } from './specificationsPdf'

export const supportCommissionDownload = {
  settlementsPdf(year: number, month: number, agencyCode?: string) {
    return settlementsPdf(year, month, agencyCode)
  },
  specificationsPdf(year: number, month: number, agencyCode?: string) {
    return specificationsPdf(year, month, agencyCode)
  },
  specificationsCsv(year: number, month: number, agencyCode?: string) {
    return specificationsCsv(year, month, agencyCode)
  },
}
