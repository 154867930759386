import { ContractStatusCode } from 'src/types/contractStatus'
import { HikisariStatusCode } from 'src/types/hikisari'

import { csvExport } from './csvExport'
import { fetchContract } from './fetchContract'
import { fetchContracts } from './fetchContracts'

export const contracts = {
  index(
    agencyCode?: string,
    page?: number,
    keyword?: string,
    statuses?: ContractStatusCode[],
    selectedHikisariStatuses?: HikisariStatusCode[],
    startFirstContractDate?: Date,
    endFirstContractDate?: Date,
    startFirstPaymentDate?: Date,
    endFirstPaymentDate?: Date
  ) {
    return fetchContracts(
      agencyCode,
      page,
      keyword,
      statuses,
      selectedHikisariStatuses,
      startFirstContractDate,
      endFirstContractDate,
      startFirstPaymentDate,
      endFirstPaymentDate
    )
  },
  show(id: string, agencyCode?: string) {
    return fetchContract(id, agencyCode)
  },
  csvExport(
    agencyCode?: string,
    keyword?: string,
    statuses?: ContractStatusCode[],
    selectedHikisariStatuses?: HikisariStatusCode[],
    startFirstContractDate?: Date,
    endFirstContractDate?: Date,
    startFirstPaymentDate?: Date,
    endFirstPaymentDate?: Date
  ) {
    return csvExport(
      agencyCode,
      keyword,
      statuses,
      selectedHikisariStatuses,
      startFirstContractDate,
      endFirstContractDate,
      startFirstPaymentDate,
      endFirstPaymentDate
    )
  },
}
