import { AxiosResponse } from 'axios'
import axios from 'src/lib/axios'
import { ActionLog } from 'src/types/actionLog'

export const fetchActionLogs = async (
  agencyCode: string,
  userId?: number,
  page?: number
): Promise<AxiosResponse> => {
  const response = await axios
    .get<{ data: ActionLog }>('/api/action-logs', {
      params: {
        id: userId,
        agencyCode,
        page,
      },
    })
    .catch((error) => {
      return error.response
    })

  return response
}
