import { AxiosResponse } from 'axios'
import { Recruiter } from 'src/types/recruiter'
import { RecruiterRoleType } from 'src/types/roleType'

import axios from '../../axios'

export type RecruiterUpdateRequestBody = {
  name: string
  phoneNumber?: string
  roleType: RecruiterRoleType
  isMfaActive: boolean
  agencyCode: string
}

export const updateRecruiter = async (
  id: string,
  requestBody: RecruiterUpdateRequestBody
): Promise<AxiosResponse> => {
  return axios.get(`/api/csrf-cookie`).then(() => {
    const response = axios
      .put<{ data: Recruiter }>(`/api/recruiters/${id}`, requestBody)
      .catch((error) => {
        return error.response
      })

    return response
  })
}
