import { AxiosResponse } from 'axios'
import { NewsList } from 'src/types/news'

import axios from '../../axios'

export const fetchNewsList = async (
  agencyCode: string | null,
  isAll: boolean
): Promise<AxiosResponse> => {
  const params =
    !isAll && agencyCode !== null
      ? { agencyCode, isAll: false }
      : { isAll: true }
  const response = await axios
    .get<{ data: NewsList }>('/api/news', { params })
    .catch((error) => {
      return error.response
    })

  return response
}
