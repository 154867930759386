import { Backdrop, CircularProgress } from '@material-ui/core'
import { CombinedState } from '@reduxjs/toolkit'
import type { FC, ReactNode } from 'react'
import { useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from 'src/hooks/useAuth'
import { useSelector } from 'src/store'
import { LoadingState } from 'src/store/slices/loading'
import { needPhoneNumberVerification } from 'src/utils/user'

import { ErrorDialog } from '../ErrorDialog'

type AuthGuardProps = {
  children: ReactNode
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props
  const { isAuthenticated, user } = useAuth()
  const location = useLocation()
  const [requestedLocation, setRequestedLocation] = useState('')
  const { isLoaded, errorMessage, errors } = useSelector(
    (state: CombinedState<{ loading: LoadingState }>) => state.loading
  )

  if (!isAuthenticated) {
    // 認証済みでもemailの検証ができていない場合は認証コードを入力してもらう
    if (user && !user.isEmailVerified) {
      return <Navigate to="/authentication/verify-email" />
    }
    // 電話番号の検証ができていなければ認証コードを入力してもらう
    if (user && needPhoneNumberVerification(user)) {
      return <Navigate to="/authentication/verify-phone-number" />
    }

    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname)
    }

    return <Navigate to={'/authentication/login'} />
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation('')
    return <Navigate to={requestedLocation} />
  }

  return (
    <>
      <ErrorDialog errorMessage={errorMessage} errors={errors} />
      <Backdrop
        open={isLoaded}
        sx={{ zIndex: (theme) => theme.zIndex.tooltip }}
      >
        <CircularProgress color="primary" size="3rem" />
      </Backdrop>
      {children}
    </>
  )
}

export default AuthGuard
