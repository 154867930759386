import { AxiosResponse } from 'axios'

import axios from '../../axios'

export const fileUpload = () => {
  return {
    campaignsSettlementPdf(file: File) {
      return zipUpload('campaigns/settlements/pdf/zip/upload', file)
    },
    campaignsSpecificationsPdf(file: File) {
      return zipUpload('campaigns/specifications/pdf/zip/upload', file)
    },
    campaignsSpecificationsCsv(file: File) {
      return zipUpload('campaigns/specifications/csv/zip/upload', file)
    },
    commissionSettlementPdf(file: File) {
      return zipUpload('commissions/settlements/pdf/zip/upload', file)
    },
    commissionSpecificationsPdf(file: File) {
      return zipUpload('commissions/specifications/pdf/zip/upload', file)
    },
    commissionSpecificationsCsv(file: File) {
      return zipUpload('commissions/specifications/csv/zip/upload', file)
    },
    commissionAgreementQualityCsv(file: File) {
      return zipUpload('commissions/agreement-quality/csv/zip/upload', file)
    },
    supportsSettlementPdf(file: File) {
      return zipUpload('supports/settlements/pdf/zip/upload', file)
    },
    supportsSpecificationsPdf(file: File) {
      return zipUpload('supports/specifications/pdf/zip/upload', file)
    },
    supportsSpecificationsCsv(file: File) {
      return zipUpload('supports/specifications/csv/zip/upload', file)
    },
  }
}

export const zipUpload = async (
  path: string,
  file: File
): Promise<AxiosResponse> => {
  const requestBody = new FormData()
  requestBody.append('file', file)
  const headers = { 'content-type': 'multipart/form-data' }

  const response = axios
    .post(`/api/${path}`, requestBody, { headers })
    .catch((error) => {
      return error.response
    })

  return response
}
