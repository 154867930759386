import { Backdrop, CircularProgress } from '@material-ui/core'
import type { FC, ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import { CombinedState } from 'redux'
import { useSelector } from 'src/store'
import { LoadingState } from 'src/store/slices/loading'

import { useAuth } from '../../hooks/useAuth'
import { ErrorDialog } from '../ErrorDialog'

interface GuestGuardProps {
  children: ReactNode
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const { isAuthenticated } = useAuth()
  const { isLoaded, errorMessage, errors } = useSelector(
    (state: CombinedState<{ loading: LoadingState }>) => state.loading
  )

  if (isAuthenticated) {
    return <Navigate to="/" />
  }

  return (
    <>
      <ErrorDialog errorMessage={errorMessage} errors={errors} />
      <Backdrop
        open={isLoaded}
        sx={{ zIndex: (theme) => theme.zIndex.tooltip }}
      >
        <CircularProgress color="primary" size="3rem" />
      </Backdrop>
      {children}
    </>
  )
}

export default GuestGuard
