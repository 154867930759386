import { AxiosResponse } from 'axios'

import axios from '../../axios'

export type PasswordChangeRequestBody = {
  previousPassword: string
  password: string
  passwordConfirmation: string
}

export const passwordChange = async (
  requestBody: PasswordChangeRequestBody
): Promise<AxiosResponse> => {
  const response = await axios
    .put(`/api/password/change`, {
      previousPassword: requestBody.previousPassword,
      password: requestBody.password,
      password_confirmation: requestBody.passwordConfirmation,
    })
    .catch((error) => {
      return error.response
    })

  return response
}
