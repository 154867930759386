import { AxiosResponse } from 'axios'

import axios from '../../axios'

export const logout = async (): Promise<AxiosResponse> => {
  const response = await axios.post(`/logout`).catch((error) => {
    return error.response
  })

  return response
}
