import { AxiosResponse } from 'axios'
import { CommissionConfirmExistence } from 'src/types/commissionConfirmExistence'

import axios from '../../axios'

export const commission = {
  confirmExistence(year: number, month: number, agencyCode?: string) {
    return confirmExistence(year, month, agencyCode)
  },
}

const confirmExistence = async (
  year: number,
  month: number,
  agencyCode?: string
): Promise<AxiosResponse> => {
  const response = await axios
    .get<{ data: CommissionConfirmExistence }>(
      `/api/commissions/confirmExistence`,
      {
        params: { agencyCode, year, month },
      }
    )
    .catch((error) => {
      return error.response
    })

  return response
}
