import { cancel } from './cancel'
import {
  changePaymentMethod,
  HozenChangePaymentMethodType,
} from './changePaymentMethod'

export const hozen = {
  cancel(managementNumber: string) {
    return cancel(managementNumber)
  },
  changePaymentMethod(
    managementNumber: string,
    paymentMethod: HozenChangePaymentMethodType
  ) {
    return changePaymentMethod(managementNumber, paymentMethod)
  },
}
