import { AxiosResponse } from 'axios'

import axios from '../../axios'

export const destroyNews = async (id: string): Promise<AxiosResponse> => {
  const response = await axios.delete(`/api/news/${id}`).catch((error) => {
    return error.response
  })

  return response
}
