import { AxiosResponse } from 'axios'
import { Recruiter } from 'src/types/recruiter'
import { RecruiterRoleType } from 'src/types/roleType'

import axios from '../../axios'

export type RecruiterCreateRequestBody = {
  name: string
  email: string
  phoneNumber?: string
  roleType: RecruiterRoleType
  agencyCode: string
}

export const createRecruiter = async (
  requestBody: RecruiterCreateRequestBody
): Promise<AxiosResponse> => {
  return axios.get(`/api/csrf-cookie`).then(() => {
    const response = axios
      .post<{ data: Recruiter }>(`/api/recruiters`, requestBody)
      .catch((error) => {
        return error.response
      })

    return response
  })
}
