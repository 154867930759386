import { AxiosResponse } from 'axios'

import axios from '../../axios'

export const downloadRecruiterList = async (
  agencyCodeList: string[]
): Promise<AxiosResponse> => {
  const response = await axios
    .post(`/api/recruiters/download`, {
      agencyCodeList,
    })
    .catch((error) => {
      return error.response
    })

  return response
}
