import { AxiosResponse } from 'axios'
import { User } from 'src/types/user'

import axios from '../../axios'

export const smsAuthentication = async (
  email: string,
  smsVerificationCode: string,
  session: string
): Promise<AxiosResponse> => {
  await axios.get(`/api/csrf-cookie`)
  const response = await axios
    .post<{ user: User }>(`/api/sms-authentication`, {
      email,
      smsVerificationCode,
      session,
    })
    .catch((error) => {
      return error.response
    })

  return response
}
